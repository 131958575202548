// app/javascript/controllers/auto_submit_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  submitOnEscape(event) {
    if (event.key === "Escape") {
      const cancelLink = this.element.parentElement.querySelector("a.btn-secondary")
      if (cancelLink) cancelLink.click()
    }
  }
}