import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="sites--transfer"
export default class extends Controller {
  connect() {
    this.idField = document.querySelector("#new_site_id");
    this.siteSearchModal = document.querySelector("#sites_search");
    this.siteTransferButton = document.querySelector("#site_transfer_button");

    document.addEventListener("sites--search:select", (event) => {
      this.populateSiteId(event.detail.siteId);
    });
  }

  populateSiteId(siteId) {
    this.idField.value = siteId;
    this.siteSearchModal.style.display = "none";
    this.siteTransferButton.disabled = false;
  }
}
