// app/javascript/controllers/form_toggle_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form"]

  connect() {
    console.log("Form toggle controller connected") // For debugging
  }

  show(event) {
    event.preventDefault()
    console.log("Show clicked") // For debugging
    this.formTarget.classList.remove("hidden")
    this.formTarget.style.display = "block"
  }

  hide(event) {
    event.preventDefault()
    console.log("Hide clicked") // For debugging
    this.formTarget.classList.add("hidden")
    this.formTarget.style.display = "none"
  }
}