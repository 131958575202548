import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"
import Dropzone from "dropzone"

export default class extends Controller {
  static targets = [
    "spcc",
    "ust",
    "third",
    "additional",
    "tier",
    "permit"
  ]

  static values = {
    siteId: String
  }

  connect() {
    this.addEventListeners()
    this.dragStartSection = null
    this.isDraggingAttachment = false
    this.setupDropzones()
  }

  // =============== DROPZONE SETUP AND HANDLING ===============

  setupDropzones() {
    // Setup dropzone for each target section except UST
    this.constructor.targets.forEach(targetName => {
      if (this[`${targetName}Target`] && targetName !== 'ust') {
        this.initializeDropzone(this[`${targetName}Target`], targetName)
      }
    })

    // Setup for dynamic targets
    const dynamicTargets = ["doc", "photo"]
    dynamicTargets.forEach(targetName => {
      const target = this.element.querySelector(`[data-attachment-drag-target="${targetName}"]`)
      if (target) {
        this.initializeDropzone(target, targetName)
      }
    })
  }

  initializeDropzone(element, sectionId) {
    const dropzoneConfig = {
      url: this.getUploadUrl(),
      method: "post",
      clickable: false,
      paramName: "upload_data",
      maxFilesize: 256,
      parallelUploads: 1,
      acceptedFiles: ".doc, .pdf, .jpg, .jpeg, .png",
      autoProcessQueue: true,
      addRemoveLinks: false,
      createImageThumbnails: true,
      headers: {
        "X-CSRF-Token": this.getMetaValue("csrf-token"),
        Accept: "text/vnd.turbo-stream.html",
      },
      init: () => {
        this.setupDropzoneEvents(element.dropzone, sectionId)
      }
    }

    element.dropzone = new Dropzone(element, dropzoneConfig)
  }

  setupDropzoneEvents(dropzone, sectionId) {
    dropzone.on("addedfile", (file) => {
      file.section = sectionId
    })

    dropzone.on("sending", (file, xhr, formData) => {
      formData.append("target_section", file.section)
      formData.append("site_id", this.siteIdValue)
      formData.append("attachment[name]", file.name)
      formData.append("attachment[site_id]", this.siteIdValue)
      formData.append("authenticity_token", this.getMetaValue("csrf-token"))
    })

    dropzone.on("success", (file, response) => {
      // Just process the Turbo Stream response and remove the file preview
      Turbo.renderStreamMessage(response)
      dropzone.removeFile(file)
      
      // Show success feedback
      this.highlightSection(file.section, 'success')
    })

    dropzone.on("error", (file, errorMessage) => {
      console.error("Upload error:", errorMessage)
      const section = this.element.querySelector(`[data-attachment-drag-target="${file.section}"]`)
      if (section) {
        this.highlightSection(file.section, 'error')
        this.showErrorMessage(errorMessage, section)
      }
      dropzone.removeFile(file)
    })
  }

  // =============== DRAG AND DROP EVENT LISTENERS ===============

  addEventListeners() {
    this.constructor.targets.forEach(targetName => {
      if (this[`${targetName}Target`]) {
        this.addListenersToTarget(this[`${targetName}Target`])
      }
    })

    const dynamicTargets = ["doc", "photo"]
    dynamicTargets.forEach(targetName => {
      const target = this.element.querySelector(`[data-attachment-drag-target="${targetName}"]`)
      if (target) {
        this.addListenersToTarget(target)
      }
    })
  }

  addListenersToTarget(target) {
    target.addEventListener("dragover", this.dragOver.bind(this))
    target.addEventListener("dragleave", this.dragLeave.bind(this))
    target.addEventListener("drop", this.dropEvent.bind(this))
    target.addEventListener("dragstart", this.dragStart.bind(this))
    target.addEventListener("dragend", this.dragEnd.bind(this))
  }

  // =============== DRAG AND DROP EVENT HANDLERS ===============

  dragOver(event) {
    event.preventDefault()
    const currentTarget = event.currentTarget
    const targetSection = currentTarget.dataset.attachmentDragTarget
    
    // Don't show drag-over effect for UST section if dragging a file
    if (targetSection === 'ust' && !this.isDraggingAttachment) {
      return
    }
    
    if (currentTarget !== this.dragStartSection) {
      currentTarget.classList.add('drag-over')
    }
  }

  dragLeave(event) {
    event.preventDefault()
    const currentTarget = event.currentTarget
    if (currentTarget !== this.dragStartSection) {
      currentTarget.classList.remove('drag-over')
    }
  }

  dragStart(event) {
    const attachmentElement = event.target.closest('[data-attachment-id]')
    if (attachmentElement) {
      this.isDraggingAttachment = true
      const attachmentId = attachmentElement.dataset.attachmentId
      event.dataTransfer.setData("attachmentId", attachmentId)
      event.dataTransfer.setData("application/x-attachment", "true")
      this.dragStartSection = event.target.closest('[data-attachment-drag-target]')
    }
  }

  dragEnd(event) {
    this.isDraggingAttachment = false
    document.querySelectorAll('.drag-over').forEach(element => {
      element.classList.remove('drag-over')
    })
  }

  dropEvent(event) {
    event.preventDefault()
    event.stopPropagation()
    
    const currentTarget = event.currentTarget
    const targetSection = currentTarget.dataset.attachmentDragTarget
    
    // Prevent file drops on UST section
    if (targetSection === 'ust' && !event.dataTransfer.types.includes('application/x-attachment')) {
      return
    }
    
    currentTarget.classList.remove('drag-over')

    if (event.dataTransfer.types.includes('application/x-attachment')) {
      const attachmentId = event.dataTransfer.getData("attachmentId")
      if (attachmentId) {
        this.moveAttachment(attachmentId, targetSection)
      }
    }
  }

  // =============== ATTACHMENT OPERATIONS ===============

  moveAttachment(attachmentId, targetSection) {
    if (!attachmentId) return

    const targetSectionElement = this.element.querySelector(`[data-attachment-drag-target="${targetSection}"]`)
    if (!targetSectionElement) return

    // Show loading state
    targetSectionElement.classList.add('attachment-loading')

    fetch(`/attachments/${attachmentId}/move`, {
      method: "PATCH",
      headers: {
        "X-CSRF-Token": this.getMetaValue("csrf-token"),
        "Content-Type": "application/json",
        "Accept": "text/vnd.turbo-stream.html"
      },
      body: JSON.stringify({ target_section: targetSection }),
    })
    .then(response => {
      targetSectionElement.classList.remove('attachment-loading')
      
      if (!response.ok) {
        // Try to parse error message from response
        return response.text().then(text => {
          try {
            const json = JSON.parse(text)
            throw new Error(json.error || 'Move failed')
          } catch (e) {
            throw new Error('Failed to move attachment')
          }
        })
      }
      return response.text()
    })
    .then(html => {
      // Only process if it's a successful response (not error text)
      if (!html.includes('error')) {
        this.highlightSection(targetSection, 'success')
        setTimeout(() => {
          Turbo.renderStreamMessage(html)
        }, 1000)
      }
    })
    .catch(error => {
      console.error("Error moving attachment:", error)
      
      // Remove loading state if still present
      targetSectionElement.classList.remove('attachment-loading')
      
      // Show error feedback
      this.highlightSection(targetSection, 'error')
      this.showErrorMessage(error.message, targetSectionElement)

      // Restore the original position if possible
      if (this.dragStartSection) {
        const attachment = document.querySelector(`[data-attachment-id="${attachmentId}"]`)
        if (attachment) {
          this.dragStartSection.querySelector('ul').appendChild(attachment)
        }
      }
    })
    .finally(() => {
      // Clean up
      targetSectionElement.classList.remove('attachment-loading')
    })
  }

  // =============== UI FEEDBACK METHODS ===============

  showErrorMessage(message, section) {
    this.clearMessages()
      
    const errorDiv = document.createElement('div')
    errorDiv.classList.add('attachment-error-message')
    errorDiv.textContent = typeof message === 'string' ? message : 'Operation failed'
      
    // Insert the error message
    const title = section.querySelector('h3')
    if (title) {
      title.insertAdjacentElement('afterend', errorDiv)
    } else {
      section.insertAdjacentElement('afterbegin', errorDiv)
    }

    // Auto remove after timeout
    setTimeout(() => {
      errorDiv.remove()
    }, 3000)
  }

  clearMessages() {
    document.querySelectorAll('.attachment-error-message').forEach(message => {
      message.remove()
    })
  }

  highlightSection(sectionId, type = 'success') {
    const section = this.element.querySelector(`[data-attachment-drag-target="${sectionId}"]`)
    if (!section) return

    // Remove any existing highlights first
    section.classList.remove('highlight-success', 'highlight-error')
    
    const className = type === 'success' ? 'highlight-success' : 'highlight-error'
    section.classList.add(className)
    
    setTimeout(() => {
      section.classList.remove(className)
    }, 3000)
  }

  // =============== UTILITY METHODS ===============

  getUploadUrl() {
    return `/sites/${this.siteIdValue}/attachments`
  }

  getMetaValue(name) {
    const element = document.querySelector(`meta[name="${name}"]`)
    return element.getAttribute("content")
  }

  disconnect() {
    // Clean up dropzones
    this.constructor.targets.forEach(targetName => {
      if (this[`${targetName}Target`]?.dropzone) {
        this[`${targetName}Target`].dropzone.destroy()
      }
    })
  }
}