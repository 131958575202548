// app/javascript/controllers/sites/search_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["siteId", "siteName"]

  connect() {
    console.log("Sites search controller connected")
    console.log("Element:", this.element)
    
    // Log the initial values
    // console.log("Initial site_id value:", this.hasSiteIdTarget ? this.siteIdTarget.value : "Target not found")
    // console.log("Initial site_name content:", this.hasSiteNameTarget ? this.siteNameTarget.textContent : "Target not found")

    // Listen for the custom siteSelected event
    document.addEventListener('siteSelected', this.handleSiteSelected.bind(this))
  }

  disconnect() {
    // Remove the event listener when the controller is disconnected
    document.removeEventListener('siteSelected', this.handleSiteSelected.bind(this))
  }

  select(e) {
    e.preventDefault()
    console.log("Select method called")

    const siteId = e.target.dataset.site
    const siteName = e.target.closest('tr').querySelector('td').textContent.trim()

    // console.log(`Selected site: ${siteId} - ${siteName}`)

    // Dispatch a custom event for the battery form
    const siteSelectedEvent = new CustomEvent('siteSelected', {
      bubbles: true,
      detail: { siteId, siteName }
    })
    document.dispatchEvent(siteSelectedEvent)

    // Dispatch an event for the transfer controller
    const transferEvent = new CustomEvent('sites--search:select', {
      bubbles: true,
      detail: { siteId }
    })
    document.dispatchEvent(transferEvent)

    // Close the modal
    const modal = document.querySelector('#sites_search')
    if (modal) {
      console.log("Closing modal")
      modal.style.display = 'none'
    } else {
      console.log("Modal not found")
    }
  }

  handleSiteSelected(event) {
    const { siteId, siteName } = event.detail
    console.log("handleSiteSelected called with:", { siteId, siteName })

    if (this.hasSiteIdTarget && this.hasSiteNameTarget) {
      // console.log("Before update - site_id value:", this.siteIdTarget.value)
      // console.log("Before update - site_name content:", this.siteNameTarget.textContent)

      this.siteIdTarget.value = siteId
      this.siteNameTarget.textContent = siteName

      // console.log("After update - site_id value:", this.siteIdTarget.value)
      // console.log("After update - site_name content:", this.siteNameTarget.textContent)

      // console.log("Form updated successfully")

      // Trigger a change event on the site_id field
      this.siteIdTarget.dispatchEvent(new Event('change', { bubbles: true }))
      // console.log("Change event dispatched on site_id field")
    } else {
      console.log("Form elements not found in handleSiteSelected")
    }
  }
  
  assignFrc(e) {
    e.preventDefault();
    const siteId = parseInt(e.target.dataset.site, 10);
    const frcSelect = e.target.parentNode.querySelector("select");
    const frcTypeId = parseInt(frcSelect.value, 10);
    const frcTypeName = frcSelect.selectedOptions[0].textContent;
    const siteName = e.target.dataset.sitename;
    const siteState = e.target.dataset.sitestate;
    this.dispatch("assignFrc", {
      detail: { frcTypeId, siteId, frcTypeName, siteName, siteState },
    });
  }

  assignUst(e) {
    e.preventDefault();
    const siteId = parseInt(e.target.dataset.site, 10);
    const siteName = e.target.dataset.sitename;
    const siteState = e.target.dataset.sitestate;
    const ustSelect = e.target.parentNode.querySelector("select");
    const ustId = parseInt(ustSelect.value, 10);
    const ustName = ustSelect.selectedOptions[0].textContent;

    this.dispatch("assignUst", {
      detail: { ustId, siteId, siteName, ustName, siteState },
    });
  }
}
