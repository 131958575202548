import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="attachments--edit"
export default class extends Controller {
  static targets = ["ustCheckbox", "ustUserSelect", "ustUser", "otherCheckbox"];

  connect() {
    if (this.hasUstCheckboxTarget && this.ustCheckboxTarget.checked) {
      this.showUserSelect();
    }
  }

  disableUst() {
    if (this.hasUstCheckboxTarget) {
      this.ustCheckboxTarget.checked = false;
      this.hideUserSelect();
    }
  }

  handleUstChange(e) {
    const isEnabled = e.target.checked;
    if (isEnabled) {
      this.otherCheckboxTargets.forEach(
        (checkbox) => (checkbox.checked = false)
      );
      this.showUserSelect();
    } else {
      this.hideUserSelect();
    }
  }

  handleOtherCheckboxChange(e) {
    const isEnabled = e.target.checked;
    if (isEnabled) {
      const otherCheckboxes = this.otherCheckboxTargets.filter((checkbox) => {
        return checkbox.getAttribute("id") !== e.target.getAttribute("id");
      });
      otherCheckboxes.forEach((checkbox) => (checkbox.checked = false));
      this.disableUst();
    }
  }

  showUserSelect() {
    if (this.hasUstUserTarget) {
      this.ustUserTarget.classList.remove("hidden");
    }
  }

  hideUserSelect() {
    if (this.hasUstUserTarget) {
      this.ustUserTarget.classList.add("hidden");
    }
    if (this.hasUstUserSelectTarget) {
      this.ustUserSelectTarget.value = "";
    }
  }
}