// app/javascript/controllers/dropzone_controller.js
import { Controller } from "@hotwired/stimulus";
import Dropzone from "dropzone";

export default class extends Controller {
  static values = {
    siteId: String,
  };

  connect() {
    const dropzoneConfig = {
      url: this.url,
      method: "post",
      clickable: true,
      paramName: "upload_data",
      maxFilesize: 256,
      parallelUploads: 5,
      addRemoveLinks: true,
      acceptedFiles: ".doc, .pdf, .jpg, .jpeg, .png",
      previewTemplate: this.previewTemplate(),
      maxFiles: 5,
      headers: {
        "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content,
        Accept: "text/vnd.turbo-stream.html",
        "X-Requested-With": "XMLHttpRequest",
      },
      dictDefaultMessage: "Drop files here",
      createImageThumbnails: true,
      previewsContainer: this.element,

      init: function() {
        this.on("addedfile", (file) => {
          console.log("File added:", file.name);
          this.element.classList.add('dz-started');
          console.log("Added dz-started class");
        });

        this.on("removedfile", (file) => {
          console.log("File removed:", file.name);
          if (this.files.length === 0) {
            this.element.classList.remove('dz-started');
            console.log("Removed dz-started class");
          }
        });

        this.on("success", (file, response) => {
          console.log("File uploaded successfully:", file.name);
          const parser = new DOMParser();
          const doc = parser.parseFromString(response, "text/html");
          const turboStreamElements = doc.querySelectorAll("turbo-stream");

          turboStreamElements.forEach((element) => {
            Turbo.renderStreamMessage(element.outerHTML);
          });

          // Handle the nested structure
          const nestedContent = doc.querySelector(".attachments-forms__form > div");
          if (nestedContent) {
            const previewElement = file.previewElement;
            previewElement.innerHTML = ''; // Clear existing content
            previewElement.appendChild(nestedContent);
            previewElement.classList.add('attachment-details');
          }
        });

        this.on("error", (file, errorMessage) => {
          console.error("Error uploading file:", file.name, errorMessage);
        });
      },

      removedfile: function (file) {
        if (file.serverId) {
          $.ajax({
            type: "DELETE",
            url: "/uploads/" + file.serverId,
            success: function (data) {
              file.previewElement.remove();
            },
            error: function (xhr, status, error) {
              console.error("Error removing file:", error);
            },
          });
        } else {
          file.previewElement.remove();
        }
      },
    };

    this.dropzone = new Dropzone(this.element, dropzoneConfig);
  }

  get url() {
    return `/sites/${this.siteIdValue}/attachments`;
  }

  previewTemplate() {
    return `
      <div class="dz-preview dz-file-preview">
        <div class="dz-image"><img data-dz-thumbnail /></div>
        <div class="dz-details">
          <div class="dz-filename"><span data-dz-name></span></div>
          <div class="dz-size" data-dz-size></div>
        </div>
        <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
        <div class="dz-error-message"><span data-dz-errormessage></span></div>
      </div>
    `;
  }
}