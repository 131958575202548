import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="inspection-checklists--new"
export default class extends Controller {
  static targets = ["answer"];

  connect() {
    if (this.element.dataset.action === "new") {
      this.filterQuestionFields("1");
    }
  }

  handleTypeChange(event) {
    this.filterQuestionFields(event.target.value);
  }

  filterQuestionFields(inspectionType) {
    const answers = this.answerTargets;

    const answersToFilterOut = answers.filter(
      (answer) => answer.dataset.inspectionType !== inspectionType
    );

    const answersToShow = answers.filter(
      (answer) => answer.dataset.inspectionType === inspectionType
    );

    answersToFilterOut.forEach((answer) => {
      answer.querySelector("textarea").value = "";
      answer.classList.add("hidden");
    });

    answersToShow.forEach((answer) => answer.classList.remove("hidden"));
  }
}
