import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="users--frc-assignment"
export default class extends Controller {
  connect() {
    document.addEventListener("sites--search:assignFrc", ({ detail }) => {
      const { frcTypeId, siteId, siteName, frcTypeName, siteState } = detail;
      this.addFrcAssignment(
        frcTypeId,
        siteId,
        siteName,
        frcTypeName,
        siteState
      );
    });
  }

  addFrcAssignment(frcTypeId, siteId, siteName, frcTypeName, siteState) {
    const hiddenFrc = document.querySelector(".frc__item.hidden");
    const frcContainer = hiddenFrc.parentElement;
    const newHiddenFrc = hiddenFrc.cloneNode(true);
    frcContainer.append(newHiddenFrc);

    const lastIndexField = document.querySelector(".frc__last-index");

    const lastIndex = lastIndexField.value;

    const newFieldIndex = lastIndex + 1;

    lastIndexField.setAttribute("value", newFieldIndex);

    hiddenFrc.querySelector(".frc__name").textContent = siteName;
    hiddenFrc.querySelector(".frc__state").textContent = siteState;
    hiddenFrc.querySelector(".frc__type").textContent = frcTypeName;
    hiddenFrc
      .querySelector(".frc__destroy")
      .setAttribute(
        "name",
        `user[frcs_attributes][${newFieldIndex}][_destroy]`
      );
    const siteIdField = hiddenFrc.querySelector(".frc__site-id");
    siteIdField.setAttribute("value", siteId);
    siteIdField.setAttribute(
      "name",
      `user[frcs_attributes][${newFieldIndex}][site_id]`
    );

    const typeIdField = hiddenFrc.querySelector(".frc__type-id");
    typeIdField.setAttribute("value", frcTypeId);
    typeIdField.setAttribute(
      "name",
      `user[frcs_attributes][${newFieldIndex}][frctype_id]`
    );

    hiddenFrc.classList.remove("hidden");
  }

  toggleDelete(e) {
    const checkbox = e.target;
    const isChecked = checkbox.checked;

    const destroyField = checkbox.parentElement.querySelector(".frc__destroy");
    if (isChecked) {
      destroyField.setAttribute("value", "");
    } else {
      destroyField.setAttribute("value", "true");
    }
  }
}
