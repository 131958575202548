// app/javascript/controllers/inventory_item_hover_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["buttons"]

  connect() {
    // Ensure buttons are hidden initially
    this.hide()
  }

  show() {
    this.buttonsTarget.classList.remove("hidden")
  }

  hide() {
    this.buttonsTarget.classList.add("hidden")
  }
}